import React, { useEffect } from 'react';
import About from './About';
import Projects from './Projects';
import Contact from './Contact';
import Hero from './Hero';
import axios from 'axios';

const backendURL = process.env.REACT_APP_BACKEND_REMOTE_URL || process.env.REACT_APP_BACKEND_LOCAL_URL;
const ipGeoUrl = process.env.REACT_APP_IPGEOLOCATION_ABSTRACT_API_KEY;

export default function Home() {
    useEffect(() => {
        function sendEmail() {
            axios.get(`https://ipgeolocation.abstractapi.com/v1/?api_key=${ipGeoUrl}`)
                .then(response1 => {
                    axios.get(`https://ipwhois.app/json/${response1.data.ip_address}`)
                        .then(response2 => {
                            const { ip_address, timezone } = response1.data;
                            const { region, city, latitude, longitude, org, isp, continent, continent_code, country, country_code, country_capital, country_phone } = response2.data;
                            try {
                                fetch(`${backendURL}/users/sendemailforpersonalpage`, {
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json' },
                                    body: JSON.stringify({
                                        name: "...", 
                                        emailAddress: "...", 
                                        message: `home page of portfolio viewed from <br />
                                            region: ${region}<br /> 
                                            city: ${city}<br />
                                            timezone.current_time: ${timezone.current_time}<br /> 
                                            timezone.abbreviation: ${timezone.abbreviation}<br /> 
                                            timezone.name: ${timezone.name}<br />
                                            latitude: ${latitude}<br /> 
                                            longitude: ${longitude}<br /> 
                                            org: ${org}<br /> 
                                            isp: ${isp}<br /> 
                                            https://ipwhois.app/json/${ip_address}
                                            continent: ${continent}<br /> 
                                            continent_code: ${continent_code}<br /> 
                                            country: ${country}<br /> 
                                            country_code: ${country_code}<br /> 
                                            country_capital: ${country_capital}<br /> 
                                            country_phone: ${country_phone}<br />`
                                    })
                                });
                            } catch (error) { console.log('error:', error) }
                        });
                });
        }
        if(backendURL && ipGeoUrl) sendEmail();
    }, []);

    return (
        <>
            <section id="home">
                <Hero />
            </section>
            <section id="about">
                <About />
            </section>
            <section id="projects">
                <Projects />
            </section>
            <section id="contact">
                <Contact />
            </section>
        </>
    );
}