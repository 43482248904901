import { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { GrLinkedin } from 'react-icons/gr';
import { ImGithub } from 'react-icons/im';

export default function MainNavbar() {
    const [expanded, setExpanded] = useState(false);

    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            // Close navbar on small screens
            setExpanded(false);
            
            // Scroll to section with offset for navbar height
            const yOffset = -80;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    return (
        <Navbar 
            collapseOnSelect 
            expand="lg" 
            className="customNavBar bg-white" 
            sticky="top"
            expanded={expanded}
            onToggle={(isExpanded) => setExpanded(isExpanded)}
        >
            <Container>
                <Navbar.Brand onClick={() => scrollToSection('home')} style={{ cursor: 'pointer' }}>
                    <img src="/my-emoji.png" alt="My Emoji" width="50" height="50" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto align-items-center">
                        <Nav.Link 
                            onClick={() => scrollToSection('about')} 
                            className="nav-link fs-5"
                        >
                            About
                        </Nav.Link>
                        <Nav.Link 
                            onClick={() => scrollToSection('projects')} 
                            className="nav-link fs-5"
                        >
                            Projects
                        </Nav.Link>
                        <Nav.Link 
                            onClick={() => scrollToSection('contact')} 
                            className="nav-link fs-5"
                        >
                            Contact
                        </Nav.Link>
                        <Nav.Link 
                            className="fs-4" 
                            href="https://www.linkedin.com/in/amin-sajedian" 
                            target="_blank"
                        >
                            <GrLinkedin />
                        </Nav.Link>
                        <Nav.Link 
                            className="fs-4" 
                            href="https://github.com/AminSajedian" 
                            target="_blank"
                        >
                            <ImGithub />
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}