import React from "react";
import MainNavbar from './components/MainNavbar';
import Home from './components/Home';

export default function App() {
    return (
        <>
            <MainNavbar />
            <Home />
        </>
    );
}